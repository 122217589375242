import { $t } from '@/i18n';
import useValidate from '@/use/validate';
import beforeLoad from '@/guards/ibm/before-load';
import { submitPersonal, submitFinalStep } from '@/handlers/shared';

const {
  isEmail,
  isPassword,
  isWeakPhone,
} = useValidate();

export default () => ({
  id: 'ibm',

  fallbackFlowId: process.env.VUE_APP_DEFAULT_FLOW,

  guards: {
    beforeLoad,
  },

  eloqua: {
    formName: 'ibm_7324_webform',
    campaignId: '7015w000000vmjEAAQ',
  },

  steps: [
    {
      id: 'home',
      routeName: null,
      component: {
        path: 'views/shared/HomeView',
      },
      isPublic: true,
      headerTheme: 'blue',
      saveSessionOnSubmit: false,
      analyticsMetaFields: {
        eloquaFormName: $t('flows.ibm.steps.home.eloquaFormName'),
      },
      fields: [
        {
          name: 'email',
          value: '',
          defaultValue: '',
          placeholder: $t('shared.businessEmail'),
          validatorClientSide: ({ email }) => isEmail(email.value),
        },
      ],
    },

    {
      id: 'confirm',
      routeName: 'confirm',
      component: {
        path: 'views/shared/ConfirmView',
      },
      isPublic: true,
      saveSessionOnSubmit: true,
      fields: [
        {
          name: 'code',
          value: [],
          defaultValue: [],
          isOptional: true,
          omitFromSession: true,
        },
      ],
    },

    {
      id: 'profile',
      routeName: 'profile',
      component: {
        path: 'views/shared/ProfileView',
      },
      saveSessionOnSubmit: true,
      fields: [
        {
          name: 'firstName',
          value: '',
          defaultValue: '',
          placeholder: $t('shared.firstName'),
        },
        {
          name: 'lastName',
          value: '',
          defaultValue: '',
          placeholder: $t('shared.lastName'),
        },
        {
          name: 'phone',
          value: '',
          defaultValue: '',
          placeholder: $t('shared.phone'),
          validatorClientSide: ({ phone }) => isWeakPhone(phone.value),
        },
        {
          name: 'password',
          value: '',
          defaultValue: '',
          placeholder: $t('shared.password'),
          omitFromSession: true,
          validatorClientSide: ({ password }) => isPassword(password.value),
        },
        {
          name: 'country',
          value: '',
          defaultValue: '',
          placeholder: $t('shared.country'),
          isTrackedByAnalytics: true,
        },
        {
          name: 'consent',
          value: null,
          defaultValue: null,
          isOptional: true,
        },
        {
          name: 'agreeToTerms',
          value: false,
          defaultValue: false,
          validatorClientSide: ({ agreeToTerms }) => agreeToTerms.value === true,
        },
      ],
    },

    {
      id: 'personal',
      routeName: 'personal',
      component: {
        path: 'views/shared/PersonalView',
      },
      saveSessionOnSubmit: true,
      submitHandler: submitPersonal,
      fields: [
        {
          name: 'department',
          value: '',
          defaultValue: '',
          isTrackedByAnalytics: true,
          isOptionalIf: ({ departmentOther }) => !!departmentOther.value,
        },
        {
          name: 'departmentOther',
          value: '',
          defaultValue: '',
          isTrackedByAnalytics: true,
          isOptionalIf: ({ department }) => !!department.value,
        },
        {
          name: 'role',
          value: '',
          defaultValue: '',
          isTrackedByAnalytics: true,
          isOptionalIf: ({ roleOther }) => !!roleOther.value,
        },
        {
          name: 'roleOther',
          value: '',
          defaultValue: '',
          isTrackedByAnalytics: true,
          isOptionalIf: ({ role }) => !!role.value,
        },
        {
          name: 'jobTitle',
          value: '',
          defaultValue: '',
          isTrackedByAnalytics: true,
        },
      ],
    },

    {
      id: 'use-case',
      routeName: 'use-case',
      component: {
        path: 'views/ibm/UseCaseView',
      },
      saveSessionOnSubmit: true,
      submitHandler: submitFinalStep,
      fields: [
        {
          name: 'useCase',
          value: '',
          defaultValue: '',
          isTrackedByAnalytics: true,
          isOptionalIf: ({ useCaseOther }) => !!useCaseOther.value,
        },
        {
          name: 'useCaseOther',
          value: '',
          defaultValue: '',
          isTrackedByAnalytics: true,
          isOptionalIf: ({ useCase }) => !!useCase.value,
        },
      ],
    },
  ],
});
